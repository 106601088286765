<template>
  <cui-acl  :roles="['signature']" redirect>
    <div class="kit__utils__heading">
      <h5>
        <span class="mr-3">Sky Portal eSignering</span>
        <a-button style="float: right;" @click="createNewContract()" v-if="['underDevelopment'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
          Opprett ny avtale
        </a-button>
      </h5>
    </div>
    <a-table bordered :data-source="dataSource" :columns="columns" v-if="loaded" :pagination="{ pageSize: 50 }" :scroll="{ x: 1500 }">
      <template slot="epuser" slot-scope="text, record">
        <editable-cell :text="text" @change="onCellChange(record.key, 'epuser', $event)" />
      </template>
      <template slot="delete" slot-scope="text, record">
         <a @click="() => deleteContract(record.key)" v-if="record.del"><i class="fa fa-trash"></i></a>
      </template>
      <template slot="getContract" slot-scope="text, record">
         <a @click="() => getContract(record.key)">Hent kontrakt</a>
      </template>
    </a-table>
  </cui-acl>
</template>
<script>
import cuiAcl from '@/components/cleanui/system/ACL'
import { functions, firestore } from '@/services/firebase'
import moment from 'moment'
import { mapState } from 'vuex'
import exportFromJSON from 'export-from-json'
import EditableCell from '@/components/custom/visual/editableCell'
import { openNotification } from '@/services/powerAutomate'

function onChange(pagination, filters, sorter) {
  console.log('params', pagination, filters, sorter)
}

export default {
  name: 'TablesAntdFilterSorter',
  computed: {
    ...mapState(['user']),
  },
  components: {
    cuiAcl,
    EditableCell,
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      dataSource: [],
      count: 0,
      loaded: false,
      columns: [
        {
          title: 'Kontraktsnavn',
          dataIndex: 'title',
          fixed: 'left',
          width: 200,
        },
        {
          title: 'Kontraktstype',
          dataIndex: 'documentType',
        },
        {
          title: 'Navn',
          dataIndex: 'nameSigner',
        },
        {
          title: 'Etternavn',
          dataIndex: 'surnameSigner',
        },
        {
          title: 'E-post',
          dataIndex: 'emailSigner',
        },
        {
          title: 'Status',
          dataIndex: 'status',
        },
        {
          title: 'Dato Opprettet',
          dataIndex: 'createdOn',
        },
        {
          title: 'Dato Signert',
          dataIndex: 'signedOn',
        },
        {
          title: '',
          dataIndex: 'id',
          scopedSlots: { customRender: 'delete' },
        },
        {
          title: '',
          dataIndex: 'id',
          key: 'getContract',
          scopedSlots: { customRender: 'getContract' },
        },
      ],
    }
  },
  methods: {
    moment,
    onChange,
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource]
      const target = dataSource.find(item => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.dataSource = dataSource
        console.log(target)
        firestore.collection('users').doc(target.id)
          .update({
            talkmoreB2C: target.epuser,
          }).then(() => {
            openNotification(`Ep bruker oppdatert for ${target.name}`)
          })
        this.$forceUpdate()
      }
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    createNewContract() {

    },
    downloadData() {
      const data = this.dataSource
      const fileName = 'product data'
      const exportType = exportFromJSON.types.csv
      const delimiter = ';'
      exportFromJSON({ data, fileName, exportType, delimiter })
    },
    getContract(key) {
      const documentId = this.dataSource[key].documentId
      console.log(documentId)
      if (documentId) {
        functions.httpsCallable('contractSignedManual')({
          documentId,
        }).then(res => {
          console.log(res.data)
          openNotification(res.data.text)
        }).catch(err => {
          console.log(err)
        })
      } else {
        openNotification('Kontrakten kan ikke hentes der den enda ikke er sendt')
      }
    },
    deleteContract(key) {
      if (this.dataSource[key].del) {
        const signatureId = this.dataSource[key].id
        console.log(signatureId)
        functions.httpsCallable('cancelContractAPI')({
          signatureId,
          reason: 'Deleted by user',
        }).then(res => {
          console.log(res.data)
          openNotification(res.data.text)
        }).catch(err => {
          console.log(err)
        })
      } else {
        openNotification('Kontrakten kan ikke slettes')
      }
    },
  },
  async mounted () {
    // create a search bar on top to filter any value for user const res = arr.filter(obj => Object.values(obj).some(val => val.includes(search)));
    let signatureRef
    if (['signatureSeeAll'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      signatureRef = firestore.collection('signatures').orderBy('createdOn', 'desc')
      this.columns.splice(2, 0, {
        title: 'Sendt av',
        dataIndex: 'sendtBy',
      })
    } else if (['signatureSeeDepartment'].some(r => this.$store.state.user.roles.indexOf(r) >= 0)) {
      signatureRef = firestore.collection('signatures').where('department', '==', this.$store.state.user.department).orderBy('createdOn', 'desc')
    } else {
      signatureRef = firestore.collection('signatures').where('sendtBy', '==', this.$store.state.user.id).orderBy('createdOn', 'desc')
    }
    signatureRef
      .onSnapshot(snapshot => {
        this.dataSource = []
        this.count = 0
        snapshot.forEach(async (doc) => {
          const del = doc.data().status === 'under approval' || doc.data().status === 'sendt for signature'
          const sendtBy = await firestore.collection('users').doc(doc.data().sendtBy).get().then(doc => {
            return doc.data().fullName
          })
          this.dataSource.push({
            status: doc.data().status,
            documentType: doc.data().documentType,
            sendtBy,
            key: this.count,
            id: String(doc.id),
            del,
            employeeID: String(doc.data().employeeID),
            nameSigner: String(doc.data().signers[0].name),
            surnameSigner: String(doc.data().signers[0].surname),
            emailSigner: String(doc.data().signers[0].email),
            title: String(doc.data().title),
            department: (doc.data().department),
            createdOn: String(moment(doc.data().createdOn).format('DD MMMM')),
            signedOn: String(moment(doc.data().signedOn).format('DD MMMM')),
            documentId: doc.data().apidata.documentId,
          })
          this.count += 1
        })
        this.loaded = true
        console.log(this.dataSource)
      })
  },
}
</script>
